
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  props: {
    error: String,
  },
  data() {
    return {
      companyId: "",
      notFound: false,
    };
  },
  methods: {
    async selectCompany() {
      this.notFound = false;
      this.$router
        .push({
          name: "Generate",
          params: { companyId: this.companyId.toLowerCase().trim() },
        })
        .catch(() => (this.notFound = true));
    },
  },
});

/*
export default class Home extends Vue {
  companyId = "";
  error?: number;

  props: {
    error?: (x) => {
      console.log(x)
      return true;
    }
  }

  async selectCompany() {
    this.$router.push({
      name: "Generate",
      params: { companyId: this.companyId },
    });
  }
}*/
