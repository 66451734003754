
import { defineComponent } from "vue";

export default defineComponent({
  name: "languageSelector",
  methods: {
    getLangFromLocale(locale: string) {
      return locale.split("-")[0];
    },
  },
  data() {
    return {
      selected: this.getLangFromLocale(this.$i18n.locale) || "en",
      open: false,
      languages: {
        en: ["English", require(`../assets/gb.svg`)],
        pt: ["Português (Brasil)", require(`../assets/br.svg`)],
        es: ["Español", require(`../assets/es.svg`)],
      },
    };
  },
});
