<template>
  <LanguageSelector />
  <router-view />
</template>

<script>
import LanguageSelector from "@/components/languageSelector";
export default {
  components: { LanguageSelector },
};
</script>

<style lang="scss">
html,
body,
#app {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

body {
  background-color: #fff;
  background-image: url("./assets/rune-bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 0;
  color: #000;
}

#app {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 100px;
}

body,
#app,
input,
a {
  font-size: 32px;
  line-height: 39px;
  font-family: "Lato", Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

input[type="button"],
input[type="submit"],
div.button {
  display: block;
  border-radius: 37px;
  height: 74px;
  width: 500px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  border: 0;
  margin-right: auto;
  margin-left: auto;
  line-height: 74px;
}

@media only screen and (max-width: 1000px) {
  #app {
    flex-direction: column;
    overflow-y: auto;
  }

  input[type="button"],
  input[type="submit"],
  div.button {
    width: 400px;
    font-size: 24px;
  }
}
</style>
