<template>
  <div class="home">
    <form action="" @submit.prevent="selectCompany">
      <div class="error" v-if="error">
        {{ error }}
      </div>
      <img src="../assets/logo.svg" alt="Rune Logo" class="logo" />
      <input
        required
        type="text"
        v-model.trim="companyId"
        v-bind:class="{ 'not-found': notFound }"
        :placeholder="$t('placeholder.enterID')"
        autocapitalize="none"
      />
      <input type="submit" :value="$t('button.continue')" class="button" />
    </form>
  </div>
</template>

<style lang="scss" scoped>
.logo {
  width: 100%;
  margin-bottom: 100px;
}

.home {
  margin: 0;
  margin-left: 30%;
  padding: 0;
  height: 100%;
  width: 100%;
}

.error {
  border-radius: 0 0 5px 5px;
  background: rgba(255, 255, 255, 0.5);
  padding: 10px;
  border: 2px rgba(255, 0, 0, 0.8) solid;
  top: 0;
  position: absolute;
  border-top: 0;
}

form {
  height: 100%;
  width: 600px;
  max-width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}

input {
  -webkit-appearance: none;
  display: block;
  margin-bottom: 5px;
  max-width: 100%;
}

input[type="text"] {
  background-color: rgba(241, 241, 241, 0.85);
  border-radius: 5px;
  border: 0;
  padding: 10px;
  opacity: 0.85;
  letter-spacing: 0;
  color: #000;
  font-style: italic;
  font-size: 26px;
  width: 440px;
  text-align: left;
  text-transform: lowercase;

  &::placeholder {
    text-transform: initial;
  }

  &.not-found {
    border: 2px rgba(255, 0, 0, 0.8) solid;
    outline: none;
    animation: shake 0.4s 1 linear;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000;
    opacity: 1; /* Firefox */
  }
  &:not(output):-moz-ui-invalid {
    box-shadow: none;
  }

  @keyframes shake {
    0% {
      transform: translate(20px);
    }
    20% {
      transform: translate(-20px);
    }
    40% {
      transform: translate(10px);
    }
    60% {
      transform: translate(-10px);
    }
    80% {
      transform: translate(6px);
    }
    100% {
      transform: translate(0px);
    }
  }
}

input[type="submit"] {
  background: none;
  color: #000;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 26px;
  letter-spacing: 0;
  cursor: pointer;
  width: 555px;
  max-width: 100%;
}

@media only screen and (max-width: 768px) {
  .home {
    margin: 0;
  }
  form {
    padding: 30px;
    width: inherit;
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  props: {
    error: String,
  },
  data() {
    return {
      companyId: "",
      notFound: false,
    };
  },
  methods: {
    async selectCompany() {
      this.notFound = false;
      this.$router
        .push({
          name: "Generate",
          params: { companyId: this.companyId.toLowerCase().trim() },
        })
        .catch(() => (this.notFound = true));
    },
  },
});

/*
export default class Home extends Vue {
  companyId = "";
  error?: number;

  props: {
    error?: (x) => {
      console.log(x)
      return true;
    }
  }

  async selectCompany() {
    this.$router.push({
      name: "Generate",
      params: { companyId: this.companyId },
    });
  }
}*/
</script>
