<template>
  <transition name="slide">
    <div class="custom-select" @blur="open = false">
      <div class="selected" :class="{ open: open }" @click="open = !open">
        <img
          :src="languages[getLangFromLocale(selected)][1]"
          :alt="`${locale} Flag`"
          width="20"
        />
      </div>
      <div class="items" :class="{ selectHide: !open }">
        <div
          v-for="locale of $i18n.availableLocales"
          :key="`locale-${locale}`"
          v-on="
            open
              ? {
                  click: () => {
                    selected = locale;
                    open = false;
                    $i18n.locale = locale;
                  },
                }
              : {}
          "
          :class="{ highlight: locale === selected }"
        >
          <img
            :src="languages[locale][1]"
            :alt="`${locale} Flag`"
            width="20"
          />{{ languages[locale][0] }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "languageSelector",
  methods: {
    getLangFromLocale(locale: string) {
      return locale.split("-")[0];
    },
  },
  data() {
    return {
      selected: this.getLangFromLocale(this.$i18n.locale) || "en",
      open: false,
      languages: {
        en: ["English", require(`../assets/gb.svg`)],
        pt: ["Português (Brasil)", require(`../assets/br.svg`)],
        es: ["Español", require(`../assets/es.svg`)],
      },
    };
  },
});
</script>

<style scoped lang="scss">
.slide-enter-active {
  transition: all 0.3s ease;
}

.custom-select {
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 30px;
  font-size: 16px;
  top: 50px;
  right: 50px;
  position: absolute;
  width: 200px;
}

.custom-select .selected {
  color: #575757;
  padding-left: 12px;
  cursor: pointer;
  user-select: none;
  position: absolute;
  right: 0;
  opacity: 1;
  transition: opacity;
  transition-duration: 500ms;
  transition-delay: 750ms;
  z-index: 1;
}

.custom-select .selected.open {
  opacity: 0;
  transition-delay: 0ms;
  transition-duration: 100ms;
}

.custom-select .items {
  color: #575757;
  cursor: pointer;
  font-style: italic;
  font-size: 16px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition-property: opacity, left;
  transition-duration: 600ms;
  transition-delay: 10ms;

  &.selectHide {
    opacity: 0;
    left: 168px;
    cursor: inherit;
  }
}

.custom-select .items div {
  background-color: rgba($color: #fff, $alpha: 0.9);
  color: #575757;
  padding-left: 12px;
  user-select: none;
  img {
    padding-right: 13px;
  }

  &.highlight {
    color: #202020;
  }
}

.custom-select .items div:hover {
  background-color: #dddddd;
}

@media only screen and (max-width: 1268px) {
  .custom-select {
    top: 5px;
    right: 20px;
  }
}
</style>
