export const messages = {
  en: {
    placeholder: {
      enterID: "Enter your Company ID",
      simpleGeneration: "Simple & Secure Password Generation",
    },
    button: {
      continue: "Continue",
      generate: "Just Generate",
      regenerate: "Regenerate Password",
    },
    copied: "Copied",
    nav: "Password Generator",
    disclaimer: {
      remember: "Remember",
      first:
        'People can obtain your password by viewing on screen, or by "shoulder surfing" while you type.',
      important: "Important",
      second:
        "Always be aware of your surroundings when generating and typing passwords.",
    },
    slider: {
      words: {
        label: "Word Count",
        tooltip: "Choose how many words you want in your password",
      },
      numbers: {
        label: "Numbers",
        tooltip: "Choose how many numbers you want in your password",
      },
      symbols: {
        label: "Symbols",
        tooltip: "Choose how many symbols you want in your password",
      },
    },
  },
  pt: {
    placeholder: {
      enterID: "ID Da Empresa",
      simpleGeneration: "Gerador de Senhas Simples e Seguro",
    },
    button: {
      continue: "Continuar",
      generate: "Gerar Senha",
      regenerate: "Gerar Novamente",
    },
    copied: "Copiado",
    nav: "Gerador de senhas",
    disclaimer: {
      remember: "LEMBRETE",
      first:
        'As Pessoas podem obter sua senha pelo seu monitor, or através da técnica de "olhar sobre os ombros" - shoulder surfing technic - enquanto você estiver digitando.',
      important: "IMPORTANTE",
      second:
        "Sempre esteja alerta com o que acontece a sua volta, quando você estiver gerando e/ou digitando suas senhas.",
    },
    slider: {
      words: {
        label: "Palavras",
        tooltip:
          "Escolha a quantidade de palavras que você deseja em sua senha",
      },
      numbers: {
        label: "Números",
        tooltip: "Escolha a quantidade de números que você deseja em sua senha",
      },
      symbols: {
        label: "Símbolos",
        tooltip:
          "Escolha a quantidade de símbolos que você deseja em sua senha",
      },
    },
  },
  es: {
    placeholder: {
      enterID: "ID De La Compañía",
      simpleGeneration: "Generación de contraseñas simple y segura",
    },
    button: {
      continue: "Continuar",
      generate: "Generar contraseña",
      regenerate: "Regenerar contraseña",
    },
    copied: "Copiado",
    nav: "Generador de contraseñas",
    disclaimer: {
      remember: "ACUERDATE",
      first:
        'Las personas pueden obtener su contraseña visualizándola en la pantalla o "navegando por el hombro" - técnica de sholder surfing  - mientras tu escribes.',
      important: "IMPORTANTE",
      second:
        "Siempre esté atento a su entorno al generar y escribir contraseñas.",
    },
    slider: {
      words: {
        label: "Palabras",
        tooltip: "Elija la cuantía de palabras que desea en su contraseña",
      },
      numbers: {
        label: "Números",
        tooltip: "Elija la cuantía de números que desea en su contraseña",
      },
      symbols: {
        label: "Símbolos",
        tooltip: "Elija la cuantía de símbolos que desea en su contraseña",
      },
    },
  },
};
