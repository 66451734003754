import { createApp } from "vue";
import { createI18n } from "vue-i18n";

import App from "./App.vue";
import router from "./router";
import { messages } from "./lang";

// Create VueI18n instance with options
const i18n = createI18n({
  locale: navigator.languages[0] || "en-GB", // set locale
  messages,
});

const app = createApp(App).use(router).use(i18n);

app.use(i18n);
app.mount("#app");
